const hBank = [
    ["Start of American Civil War",1861,"http://media.npr.org/assets/img/2011/04/07/fortwagner_wide-72e1fc52890d03a20d0ff79473f1503b1ae357bc.jpg?s=6"],
    ["Start of World War II",1939,"https://263i3m2dw9nnf6zqv39ktpr1-wpengine.netdna-ssl.com/wp-content/uploads/2021/09/16:9/Approaching_Omaha-DDay-1200x0-c-default.jpg"],
    ["First Known Book Written",-2100,"http://cdn8.openculture.com/wp-content/uploads/2015/10/Tablet_V_of_the_Epic_of_Gligamesh._Newly_discovered._The_Sulaymaniyah_Museum_Iraq..jpg"],
    ["Spanish Flu",1918,"https://i.guim.co.uk/img/media/80cc28f99e794d7a49769f2ea8a95c72b0a3c5e6/1_324_2986_1792/master/2986.jpg?width=700&quality=85&auto=format&fit=max&s=48e451bdfdf59000a217907c54ccc540"],
    ["Black Death",1346,"https://thumbor.forbes.com/thumbor/711x392/https://blogs-images.forbes.com/davidbressan/files/2017/07/Bruegela_1562_Thetriumphofdeath.jpg?width=960"],
    ["City of London Founded",43,"https://londontopia.net/wp-content/uploads/2017/01/A004115-02.jpg"],
    ["First date on the Maya calendar",-3372,"https://cdn.mos.cms.futurecdn.net/JGA5Eb6HZ6nTP5wjuw7qCV-1200-80.jpg"],
    ["Moses gives the Ten Commandments to the Jewish people",-1240,"https://upload.wikimedia.org/wikipedia/commons/c/ca/Moses_and_Aaron_with_the_Tablets_of_the_Law_-_Google_Art_Project.jpg"],
    ["Birth of Lao-tzu (Laozi), founder of Daoism",-604,"https://www.filosofiaesoterica.com/wp-content/uploads/2019/07/Lao-tzu-on-All-Around-Mastery-sem-mold.jpg"],
    ["Siddhartha Gautama, founder of Buddhism, born", -563,"https://english.cdn.zeenews.com/sites/default/files/styles/zm_700x400/public/2017/05/10/593303-buddha-new.jpg"],
    ["Caste system established in India", -500,"https://live-production.wcms.abc-cdn.net.au/0be5c7cea6b976693b79e626bf6f0544?impolicy=wcms_crop_resize&cropH=1075&cropW=1618&xPos=137&yPos=0&width=862&height=575"],
    ["Birth of Euripides, Greek poet and dramatist", -480,"https://www.thoughtco.com/thmb/LdJ1GoF4zmejndu8CzfcoLzck5I=/3503x3503/smart/filters:no_upscale()/sophocles-bust-in-athens-downtown-517898640-f98426a14fe94659b1c626751bef0ff6.jpg"],
    ["Chinese system of imperial examinations instituted",-124,"https://study.com/cimages/videopreview/videopreview-full/chiu_ying_001_137825.jpg"],
    ["Jesus of Nazareth baptized by John the Baptist",27,"https://content.quizzclub.com/trivia/2020-01/in-which-river-was-jesus-of-nazareth-baptized-by-john-the-baptist.jpg"],
    ["Buddhism introduced into China",58,"https://www.lionsroar.com/wp-content/uploads/2020/09/Ritzinger-Shan-David-Gilbert-Flickr.jpg"],
    ["China completes the Grand Canal", 618,"https://news.cgtn.com/news/2020-11-25/Live-A-night-view-of-the-Grand-Canal-in-Hangzhou-Ep-8-VHB70XI9aw/img/e23a6ff547594573aeefd488263d2c2d/e23a6ff547594573aeefd488263d2c2d.jpeg"],
    ["Second Sino-Japanese War", 1937, "https://alphahistory.com/chineserevolution/wp-content/uploads/2014/04/nationalistsoldiers.jpg"],
    ["Boxer Rebellion", 1899, "https://www.thegreatcoursesdaily.com/wp-content/uploads/2021/02/China_QBS_Boxer-Uprising_Featured.jpg"],
    ["Babylonian King Hammurabi Issues the Code of Hammurabi", -1754, "https://www.history.com/.image/ar_16:9%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cg_faces:center%2Cq_auto:good%2Cw_768/MTU3ODc5MDgzNzUxNjQ2OTQz/diorite-stela-with-the-code-of-hammurabi-2.jpg"],
    ["First Olympic Games athletic competitions", -776, "https://www.historyonthenet.com/wp-content/uploads/2014/07/anicent-olympics-games.jpg"],
    ["Plato opens the Academy in Athens", -386, "https://rainerebert.files.wordpress.com/2015/06/schoolofathens.jpg"],
    ["Muhammad is born in Mecca", 570, "https://upload.wikimedia.org/wikipedia/commons/6/61/Birth_of_Muhammad_from_folio_44a_of_the_Jami%E2%80%98_al-tawarikh.jpg"],
    ["First production of Gunpowder", 800, "https://www.thoughtco.com/thmb/atfr4iipwukHWJ577i2Da4OW-eQ=/1885x1414/smart/filters:no_upscale()/Fire-arrow.-5c6dce6546e0fb00012d31e7.jpg"],
    ["Norse explorers discover and colonize Iceland", 870, "https://upload.wikimedia.org/wikipedia/commons/3/36/The_Norwegians_land_in_Iceland_year_872.jpg"],
    ["Genghis Khan captures the capital of the Jin Dynasty", 1215, "https://upload.wikimedia.org/wikipedia/commons/c/c0/Bataille_entre_mongols_%26_chinois_%281211%29.jpeg"],
    ["Aztec triple alliance formed", 1428, "https://www.historycrunch.com/uploads/4/1/1/6/41169839/edited/codex-osuna-triple-alliance.jpg"]
]

// export entire array 
export default hBank;
